import React from 'react';
import logo from '../../assets/maximusLogo1Transparent.svg';
import { NavLink } from 'react-router-dom';
// import WithFade from '../../hoc/Fade/withFade';

const SideBar = (props) => (
	<nav className="navbar">
			<ul className="navbar-nav">
				<li className="logo">
					<a href="/" className="nav-link">
						<span className="link-text logo-text"></span>
						<img src={logo} alt="Logo" />
					</a>
				</li>
	
				<li className="mobile_nav-item">
					<a href="/#home" className="nav-link">
					<svg
						aria-hidden="true"
						focusable="false"
						data-prefix="fad"
						data-icon="home"
						role="img"
						xmlns="http://www.w3.org/2000/svg" 
						viewBox="0 0 576 512">
								<path fill="#EB4646" className="fa-primary" d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z"/>
					</svg>
						<span className="link-text">Home</span>
					</a>
				</li>
				<li className="nav-item">
					<a href="/#work" className="nav-link">
					<svg 
						aria-hidden="true"
						focusable="false"
						data-prefix="fad"
						data-icon="home"
						role="img"
						xmlns="http://www.w3.org/2000/svg" 
						viewBox="0 0 640 512">
								<path fill="#EB4646" className="fa-primary" d="M278.9 511.5l-61-17.7c-6.4-1.8-10-8.5-8.2-14.9L346.2 8.7c1.8-6.4 8.5-10 14.9-8.2l61 17.7c6.4 1.8 10 8.5 8.2 14.9L293.8 503.3c-1.9 6.4-8.5 10.1-14.9 8.2zm-114-112.2l43.5-46.4c4.6-4.9 4.3-12.7-.8-17.2L117 256l90.6-79.7c5.1-4.5 5.5-12.3.8-17.2l-43.5-46.4c-4.5-4.8-12.1-5.1-17-.5L3.8 247.2c-5.1 4.7-5.1 12.8 0 17.5l144.1 135.1c4.9 4.6 12.5 4.4 17-.5zm327.2.6l144.1-135.1c5.1-4.7 5.1-12.8 0-17.5L492.1 112.1c-4.8-4.5-12.4-4.3-17 .5L431.6 159c-4.6 4.9-4.3 12.7.8 17.2L523 256l-90.6 79.7c-5.1 4.5-5.5 12.3-.8 17.2l43.5 46.4c4.5 4.9 12.1 5.1 17 .6z"/>
					</svg>
						<span className="link-text">Work</span>
					</a>
				</li>
				<li className="nav-item">
					<a href="/#about" className="nav-link">
					<svg 
						aria-hidden="true"
						focusable="false"
						data-prefix="fad"
						data-icon="home"
						role="img"
						xmlns="http://www.w3.org/2000/svg" 
						viewBox="0 0 576 512">
								<path fill="#EB4646" className="fa-primary" d="M528 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm0 400H48V80h480v352zM208 256c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm-89.6 128h179.2c12.4 0 22.4-8.6 22.4-19.2v-19.2c0-31.8-30.1-57.6-67.2-57.6-10.8 0-18.7 8-44.8 8-26.9 0-33.4-8-44.8-8-37.1 0-67.2 25.8-67.2 57.6v19.2c0 10.6 10 19.2 22.4 19.2zM360 320h112c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8H360c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8zm0-64h112c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8H360c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8zm0-64h112c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8H360c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8z"/>
					</svg>
						<span className="link-text">About</span>
					</a>
				</li>
				<li className="nav-item">
					<a href="/#contact" className="nav-link">
					<svg 
						aria-hidden="true"
						focusable="false"
						data-prefix="fad"
						data-icon="home"
						role="img"
						xmlns="http://www.w3.org/2000/svg" 
						viewBox="0 0 512 512">
							<path fill="#EB4646" className="fa-primary" d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"/>
					</svg>
						<span className="link-text">Contact</span>
					</a>
				</li>
				<li className="mobile-only_nav-item">
					<NavLink to="/covid19-tracker" className="nav-link">
					<svg 
						aria-hidden="true"
						focusable="false"
						data-prefix="fad"
						data-icon="home"
						role="img"
						xmlns="http://www.w3.org/2000/svg" 
						viewBox="0 0 512 512">
							<path fill="#EB4646" className="fa-primary" d="M483.55,227.55H462c-50.68,0-76.07-61.27-40.23-97.11L437,115.19A28.44,28.44,0,0,0,396.8,75L381.56,90.22c-35.84,35.83-97.11,10.45-97.11-40.23V28.44a28.45,28.45,0,0,0-56.9,0V50c0,50.68-61.27,76.06-97.11,40.23L115.2,75A28.44,28.44,0,0,0,75,115.19l15.25,15.25c35.84,35.84,10.45,97.11-40.23,97.11H28.45a28.45,28.45,0,1,0,0,56.89H50c50.68,0,76.07,61.28,40.23,97.12L75,396.8A28.45,28.45,0,0,0,115.2,437l15.24-15.25c35.84-35.84,97.11-10.45,97.11,40.23v21.54a28.45,28.45,0,0,0,56.9,0V462c0-50.68,61.27-76.07,97.11-40.23L396.8,437A28.45,28.45,0,0,0,437,396.8l-15.25-15.24c-35.84-35.84-10.45-97.12,40.23-97.12h21.54a28.45,28.45,0,1,0,0-56.89ZM224,272a48,48,0,1,1,48-48A48,48,0,0,1,224,272Zm80,56a24,24,0,1,1,24-24A24,24,0,0,1,304,328Z"/>
					</svg>
						<span className="link-text">COVID-19 Tracker</span>
					</NavLink>
				</li>
				<li className="mobile-only_nav-item">
					<NavLink to="/uses" className="nav-link">
					<svg 
						aria-hidden="true"
						focusable="false"
						data-prefix="fad"
						data-icon="home"
						role="img"
						xmlns="http://www.w3.org/2000/svg" 
						viewBox="0 0 640 512">
							<path fill="#EB4646" className="fa-primary" d="M255.03 261.65c6.25 6.25 16.38 6.25 22.63 0l11.31-11.31c6.25-6.25 6.25-16.38 0-22.63L253.25 192l35.71-35.72c6.25-6.25 6.25-16.38 0-22.63l-11.31-11.31c-6.25-6.25-16.38-6.25-22.63 0l-58.34 58.34c-6.25 6.25-6.25 16.38 0 22.63l58.35 58.34zm96.01-11.3l11.31 11.31c6.25 6.25 16.38 6.25 22.63 0l58.34-58.34c6.25-6.25 6.25-16.38 0-22.63l-58.34-58.34c-6.25-6.25-16.38-6.25-22.63 0l-11.31 11.31c-6.25 6.25-6.25 16.38 0 22.63L386.75 192l-35.71 35.72c-6.25 6.25-6.25 16.38 0 22.63zM624 416H381.54c-.74 19.81-14.71 32-32.74 32H288c-18.69 0-33.02-17.47-32.77-32H16c-8.8 0-16 7.2-16 16v16c0 35.2 28.8 64 64 64h512c35.2 0 64-28.8 64-64v-16c0-8.8-7.2-16-16-16zM576 48c0-26.4-21.6-48-48-48H112C85.6 0 64 21.6 64 48v336h512V48zm-64 272H128V64h384v256z"/>
					</svg>
						<span className="link-text">/uses</span>
					</NavLink>
				</li>
				
				<div className="bottom-items">
					<li className="mobile_nav-item">
						<NavLink to="/covid19-tracker" className="nav-link">
						<svg 
							aria-hidden="true"
							focusable="false"
							data-prefix="fad"
							data-icon="home"
							role="img"
							xmlns="http://www.w3.org/2000/svg" 
							viewBox="0 0 512 512">
								<path fill="#EB4646" className="fa-primary" d="M483.55,227.55H462c-50.68,0-76.07-61.27-40.23-97.11L437,115.19A28.44,28.44,0,0,0,396.8,75L381.56,90.22c-35.84,35.83-97.11,10.45-97.11-40.23V28.44a28.45,28.45,0,0,0-56.9,0V50c0,50.68-61.27,76.06-97.11,40.23L115.2,75A28.44,28.44,0,0,0,75,115.19l15.25,15.25c35.84,35.84,10.45,97.11-40.23,97.11H28.45a28.45,28.45,0,1,0,0,56.89H50c50.68,0,76.07,61.28,40.23,97.12L75,396.8A28.45,28.45,0,0,0,115.2,437l15.24-15.25c35.84-35.84,97.11-10.45,97.11,40.23v21.54a28.45,28.45,0,0,0,56.9,0V462c0-50.68,61.27-76.07,97.11-40.23L396.8,437A28.45,28.45,0,0,0,437,396.8l-15.25-15.24c-35.84-35.84-10.45-97.12,40.23-97.12h21.54a28.45,28.45,0,1,0,0-56.89ZM224,272a48,48,0,1,1,48-48A48,48,0,0,1,224,272Zm80,56a24,24,0,1,1,24-24A24,24,0,0,1,304,328Z"/>
						</svg>
							<span className="link-text">COVID-19 Tracker</span>
						</NavLink>
					</li>

					<li className="mobile_nav-item">
						<NavLink to="/uses" className="nav-link">
						<svg 
							aria-hidden="true"
							focusable="false"
							data-prefix="fad"
							data-icon="home"
							role="img"
							xmlns="http://www.w3.org/2000/svg" 
							viewBox="0 0 640 512">
								<path fill="#EB4646" className="fa-primary" d="M255.03 261.65c6.25 6.25 16.38 6.25 22.63 0l11.31-11.31c6.25-6.25 6.25-16.38 0-22.63L253.25 192l35.71-35.72c6.25-6.25 6.25-16.38 0-22.63l-11.31-11.31c-6.25-6.25-16.38-6.25-22.63 0l-58.34 58.34c-6.25 6.25-6.25 16.38 0 22.63l58.35 58.34zm96.01-11.3l11.31 11.31c6.25 6.25 16.38 6.25 22.63 0l58.34-58.34c6.25-6.25 6.25-16.38 0-22.63l-58.34-58.34c-6.25-6.25-16.38-6.25-22.63 0l-11.31 11.31c-6.25 6.25-6.25 16.38 0 22.63L386.75 192l-35.71 35.72c-6.25 6.25-6.25 16.38 0 22.63zM624 416H381.54c-.74 19.81-14.71 32-32.74 32H288c-18.69 0-33.02-17.47-32.77-32H16c-8.8 0-16 7.2-16 16v16c0 35.2 28.8 64 64 64h512c35.2 0 64-28.8 64-64v-16c0-8.8-7.2-16-16-16zM576 48c0-26.4-21.6-48-48-48H112C85.6 0 64 21.6 64 48v336h512V48zm-64 272H128V64h384v256z"/>
						</svg>
							<span className="link-text">/uses</span>
						</NavLink>
					</li>
				</div>
			</ul>
	</nav>
);

export default SideBar;